// extracted by mini-css-extract-plugin
export var blush = "bz_d2";
export var blushIllustration = "bz_dW";
export var button = "bz_bG";
export var cardHeadline = "bz_dX";
export var cardRoot = "bz_dV";
export var cardSubtitle = "bz_d1";
export var resourcesTileMobileVerticalContainer = "bz_d0";
export var rowTwo = "bz_dZ";
export var rowtwosignal = "bz_d3";
export var wrapper = "bz_bD";