// extracted by mini-css-extract-plugin
export var AuthorAvatarContainer = "bd_c9";
export var AuthorBioContainer = "bd_c7";
export var AuthorContainer = "bd_c6";
export var AuthorGradientBackground = "bd_c8";
export var AuthorInfoContainer = "bd_db";
export var AuthorInfoDetails = "bd_dc";
export var AuthorInfoName = "bd_dd";
export var AuthorInfoSection = "bd_df";
export var MuiPopperRoot = "bd_bB";
export var MuiTooltipPopper = "bd_bC";