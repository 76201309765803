// extracted by mini-css-extract-plugin
export var actions = "bH_d7";
export var articleMultipleChoiceCTA = "bH_fh";
export var body = "bH_fk";
export var bodyContainer = "bH_fj";
export var illustration = "bH_fm";
export var selected = "bH_fq";
export var subtitle = "bH_fp";
export var textContent = "bH_fn";
export var tile = "bH_fl";
export var wrapper = "bH_bD";